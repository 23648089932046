import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/scss/custom.scss'
import '@/assets/scss/animate.scss'
import '@/assets/scss/breakpointsForJS.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
// import { makeServer } from './server'
import i18n from '@/lang/lang.js'
import '@/dateFormat.js'
import { TablePlugin, PaginationPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(TablePlugin)
Vue.use(PaginationPlugin)
Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'development') {
  // makeServer()
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
